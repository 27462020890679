import React from "react";
import axios from "axios";
import MySwal from "utils/Swal";

const baseUrl = window.location.origin.includes("localhost")
  ? "http://localhost:3000/api/v1/"
  : window.location.origin.includes("itscredible.com")
  ? `${window.location.origin}/api/v1/`
  : window.location.origin.includes("dev1.credulocker.com")
  ? "https://dev1.itscredible.com/api/v1/"
  : "https://portal.itscredible.com/api/v1/";
const getAuthToken = () => localStorage.getItem("jwt");
const getCredulockerToken = () => localStorage.getItem("cjwt");

const customAxios = axios.create({ withCredentials: true });

customAxios.interceptors.request.use((config) => {
  if (!config.url.startsWith("http://") && !config.url.startsWith("https://")) {
    config.url = baseUrl + config.url;
    if (config.attachCredulockerToken && getCredulockerToken()) {
      config.headers.authorization = "credulocker " + getCredulockerToken();
    } else if (!config.headers?.authorization && getAuthToken()) {
      config.headers.authorization = "itscredible " + getAuthToken();
    }
  }

  return config;
});

customAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error?.response?.status !== 401 &&
      error?.response?.data?.error?.code !== "REVOKED"
    ) {
      MySwal.fire({
        title: "Error!",
        text:
          error?.response?.data?.error?.message ||
          error?.response?.data?.error?.errorMessage ||
          error?.response?.data?.err?.msg ||
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong!",
        icon: "error",
      });
    }
    return error?.response;
  },
);

const useAxios = (url, config = {}) => {
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [status, setStatus] = React.useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await customAxios.get(url, config);
    setStatus(response?.status);
    if (response?.data) {
      setData(response.data);
    } else {
      setError(true);
    }
    setIsLoading(false);
  };

  const refresh = async () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, [url]);

  return [data, isLoading, error, refresh, status];
};

export { useAxios, customAxios };
