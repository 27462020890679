import React, { Fragment, useEffect, useReducer, useState } from "react";
import {
  Box,
  Stack,
  Menu,
  MenuItem,
  ButtonGroup,
  Button,
  Icon,
  TextField,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ColorPicker from "components/common/ColorPicker";
import constants from "constants/english/builder";
import { useLocation } from "react-router-dom";

const calculateScale = (width, height) => {
  const workspaceWidth = window.innerWidth * 0.7 - 100,
    workspaceHeight = window.innerHeight - 200;
  const scale = Math.max(width / workspaceWidth, height / workspaceHeight);
  return scale;
};

const PropertiesEditor = (props) => {
  const {
    selectedElement,
    templateBgColor,
    setTemplateBgColor,
    setDimension,
    setSelectedElement,
    imageInputChangeHandler,
  } = props;
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState();
  const [credentialType, setCredentialType] = useState("Certificate");
  const [orientation, setOrientation] = useState("Landscape");
  const [, reRenderComponent] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const credWidth =
      constants.TEMPLATE_DEFAULT_DIMENSION[credentialType].width;
    const credHeight =
      constants.TEMPLATE_DEFAULT_DIMENSION[credentialType].height;
    if (location?.state?.dimension) {
      let width = 1123;
      let height =
        (location.state.dimension.height / location.state.dimension.width) *
        width;
      if (location.state.dimension.height > location.state.dimension.width) {
        height = 1123;
        width =
          (location.state.dimension.width / location.state.dimension.height) *
          height;
      }
      let scale = calculateScale(width, height);
      setDimension({
        width,
        height,
        scale,
      });
    } else if (orientation === "Landscape") {
      const scale = calculateScale(credWidth, credHeight);
      setDimension({
        width: credWidth,
        height: credHeight,
        scale: scale,
      });
    } else if (orientation === "Portrait") {
      const scale = calculateScale(credHeight, credWidth);
      setDimension({
        width: credHeight,
        height: credWidth,
        scale: scale,
      });
    } else {
      const scale = calculateScale(credHeight, credWidth);
      setDimension({
        width: credWidth,
        height: credHeight,
        scale: scale,
      });
    }
    setSelectedElement();
  }, [orientation, credentialType]);

  const fontSizes = [];
  for (let i = 2; i <= 100; i += 2) {
    fontSizes.push(`${i}px`);
  }
  const varProperties = [
    ...(selectedElement?.tagName === "IMG" ||
    selectedElement?.tagName === "SPAN"
      ? []
      : [
          {
            text: "Color",
            tooltipText: "Text Color",
            input: "color-picker",
            value: selectedElement?.style?.color,
            onChange: (color) => {
              selectedElement.style.color = color;
              reRenderComponent();
            },
          },
          {
            text: "Font",
            tooltipText: "Font",
            input: "select",
            options: constants.FONTS_FOR_TEMPLATE,
            value: selectedElement?.style?.fontFamily,
            onChange: (e) => {
              selectedElement.style.fontFamily = e?.target?.value;
              reRenderComponent();
            },
          },
          {
            text: "Size",
            tooltipText: "Font Size",
            input: "select",
            options: fontSizes,
            value: selectedElement?.style?.fontSize,
            onChange: (e) => {
              selectedElement.style.fontSize = e?.target?.value;
              reRenderComponent();
            },
          },
          {
            text: "Decoration",
            input: "buttons",
            options: {
              Bold: {
                icon: FormatBoldIcon,
                tooltipText: "Bold",
                value: selectedElement?.style?.fontWeight === "bold",
                onClick: () => {
                  selectedElement.style.fontWeight =
                    selectedElement?.style?.fontWeight === "bold"
                      ? "normal"
                      : "bold";
                  reRenderComponent();
                },
              },
              Italic: {
                icon: FormatItalicIcon,
                tooltipText: "Italics",
                value: selectedElement?.style?.fontStyle === "italic",
                onClick: () => {
                  selectedElement.style.fontStyle =
                    selectedElement?.style?.fontStyle === "italic"
                      ? "normal"
                      : "italic";
                  reRenderComponent();
                },
              },
              Underline: {
                icon: FormatUnderlinedIcon,
                tooltipText: "Underline",
                value: selectedElement?.style?.textDecoration === "underline",
                onClick: () => {
                  selectedElement.style.textDecoration =
                    selectedElement?.style?.textDecoration === "underline"
                      ? "none"
                      : "underline";
                  reRenderComponent();
                },
              },
            },
          },
          {
            text: "Alignment",
            input: "buttons",
            options: {
              Left: {
                icon: FormatAlignLeftIcon,
                tooltipText: "Left Align",
                value: selectedElement?.style?.textAlign === "left",
                onClick: () => {
                  selectedElement.style.textAlign = "left";
                  reRenderComponent();
                },
              },
              Center: {
                icon: FormatAlignCenterIcon,
                tooltipText: "Center Align",
                value: selectedElement?.style?.textAlign === "center",
                onClick: () => {
                  selectedElement.style.textAlign = "center";
                  reRenderComponent();
                },
              },
              Right: {
                icon: FormatAlignRightIcon,
                tooltipText: "Right Align",
                value: selectedElement?.style?.textAlign === "right",
                onClick: () => {
                  selectedElement.style.textAlign = "right";
                  reRenderComponent();
                },
              },
            },
          },
        ]),
    {
      text: "Arrangement",
      input: "buttons",
      options: {
        Forward: {
          icon: ArrowUpwardIcon,
          tooltipText: "Bring Forward",
          disabled: !Array.from(
            document.querySelectorAll("#template-container .selectable"),
          ).some(
            (element) =>
              element?.style?.zIndex > selectedElement?.style?.zIndex,
          ),
          onClick: () => {
            const selectedZIdx = parseInt(selectedElement.style.zIndex);
            let nextZIdx = Number.MAX_SAFE_INTEGER,
              nextZIdxElem;
            document
              .querySelectorAll("#template-container .selectable")
              .forEach((elem) => {
                const currZIdx = parseInt(elem.style.zIndex);
                if (currZIdx > selectedZIdx) {
                  if (currZIdx - selectedZIdx < nextZIdx - selectedZIdx) {
                    nextZIdx = currZIdx;
                    nextZIdxElem = elem;
                  }
                }
              });
            selectedElement.style.zIndex = nextZIdx;
            nextZIdxElem.style.zIndex = selectedZIdx;
            reRenderComponent();
          },
        },
        Backward: {
          icon: ArrowDownwardIcon,
          tooltipText: "Send Backward",
          disabled: !Array.from(
            document.querySelectorAll("#template-container .selectable"),
          ).some(
            (element) =>
              element?.style?.zIndex < selectedElement?.style?.zIndex,
          ),
          onClick: () => {
            const selectedZIdx = parseInt(selectedElement.style.zIndex);
            let prevZIdx = -1,
              prevZIdxElem;
            document
              .querySelectorAll("#template-container .selectable")
              .forEach((elem) => {
                const currZIdx = parseInt(elem.style.zIndex);
                if (currZIdx < selectedZIdx) {
                  if (selectedZIdx - currZIdx < selectedZIdx - prevZIdx) {
                    prevZIdx = currZIdx;
                    prevZIdxElem = elem;
                  }
                }
              });
            selectedElement.style.zIndex = prevZIdx;
            prevZIdxElem.style.zIndex = selectedZIdx;
            reRenderComponent();
          },
        },
      },
    },
  ];

  const changeOrientation = (credType, orient) => {
    setCredentialType(credType);
    setOrientation(orient);
    setAnchorEl();
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {selectedElement ? (
        varProperties.map((property) =>
          property.input === "select" ? (
            <Tooltip key={property.text} title={property?.tooltipText}>
              <TextField
                select
                value={property.value}
                size="small"
                className="extra-small"
                onChange={property.onChange}
              >
                {property.options.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{ fontFamily: option }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Tooltip>
          ) : property.input === "color-picker" ? (
            <ColorPicker
              key={property.text}
              tooltipText={property.tooltipText}
              value={property.value}
              onChange={property.onChange}
            />
          ) : property.input === "buttons" ? (
            <ButtonGroup key={property.text}>
              {Object.keys(property.options).map((option) => (
                <Tooltip
                  key={option.text}
                  title={property.options[option]?.tooltipText}
                >
                  <Box
                    sx={{
                      cursor: property.options[option].disabled
                        ? "not-allowed"
                        : "initial",
                      bgcolor: property.options[option].value
                        ? "primary.surface"
                        : "transparent",
                    }}
                  >
                    <Button
                      color="inherit"
                      size="small"
                      disabled={property.options[option].disabled}
                      onClick={property.options[option].onClick}
                    >
                      <Icon
                        component={property.options[option].icon}
                        color={
                          property.options[option].value
                            ? "inherit"
                            : "disabled"
                        }
                      />
                    </Button>
                  </Box>
                </Tooltip>
              ))}
            </ButtonGroup>
          ) : null,
        )
      ) : (
        <>
          <ColorPicker
            value={templateBgColor}
            tooltipText="Background Color"
            onChange={(color) => setTemplateBgColor(color)}
          />
          {location?.state?.backgroundImg ? null : (
            <Fragment>
              <Button
                variant="contained"
                size="small"
                color="inherit"
                disableElevation
                onClick={() =>
                  document.getElementById("backgroundInput").click()
                }
              >
                Upload Background Image
              </Button>
              <input
                type="file"
                id="backgroundInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={imageInputChangeHandler}
              />
              <Button
                variant="contained"
                size="small"
                color="inherit"
                disableElevation
                onClick={(e) => setAnchorEl(e.target)}
              >
                Orientation
              </Button>
            </Fragment>
          )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl()}
          >
            <ListSubheader>Certificate</ListSubheader>
            <MenuItem
              onClick={() => changeOrientation("Certificate", "Landscape")}
            >
              Landscape
            </MenuItem>
            <MenuItem
              onClick={() => changeOrientation("Certificate", "Portrait")}
            >
              Portrait
            </MenuItem>
            <ListSubheader>Badge</ListSubheader>
            <MenuItem onClick={() => changeOrientation("Badge", "Square")}>
              Square
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  );
};

export default PropertiesEditor;
