import React from "react";
import {
  Button,
  Divider,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CopyButton from "components/common/CopyButton";
import { customAxios, useAxios } from "hooks/useAxios";
import { Add } from "@mui/icons-material";
import MySwal from "utils/Swal";

const CopyValue = (props) => {
  const { value, revokeFn = false } = props;
  return (
    <Paper
      variant="outlined"
      sx={{
        backgroundColor: "primary.surface",
        px: 2,
        py: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Typography sx={{ wordWrap: "break-word", width: { sx: "100%" } }}>
        {value}
      </Typography>
      <Stack direction="row" spacing={1}>
        <CopyButton value={value} />
        {revokeFn && (
          <Button color="error" disableElevation onClick={revokeFn}>
            Revoke
          </Button>
        )}
      </Stack>
    </Paper>
  );
};

function OAuthCredTab() {
  const [data, isLoading] = useAxios("getClientFromCognito");

  const CONTENT = [
    {
      label: "Access Token URL",
      value: `https://auth${
        window.location.host.split(".")[0].toLowerCase() === "portal"
          ? ""
          : "-stage"
      }.itscredible.com/oauth2/token`,
    },
    {
      label: "Content-Type",
      value: "application/x-www-form-urlencoded",
    },
    {
      label: "grant_type",
      value: "client_credentials",
    },
    {
      label: "client_id",
      value: data?.response?.UserPoolClient?.ClientId,
    },
    {
      label: "client_secret",
      value: data?.response?.UserPoolClient?.ClientSecret,
    },
  ];

  return (
    <Stack py={3} spacing={3}>
      <Typography variant="body1">
        Please use the following resources to get the access token to use the
        APIs.
      </Typography>
      {CONTENT.map((elem) => (
        <Stack key={elem.label} direction="column" spacing={0.5}>
          <Typography variant="body1">{elem.label}</Typography>
          {isLoading ? (
            <Skeleton variant="rectangular" height={54} />
          ) : (
            <CopyValue value={elem.value} />
          )}
        </Stack>
      ))}
    </Stack>
  );
}

function APIKeyTab() {
  const [data, isLoading, , refreshKeys] = useAxios("api-keys");
  const keys = data?.response?.data || [];

  const handleNewAPIKeyButton = () => {
    MySwal.fire({
      title: "New API Key",
      input: "text",
      inputLabel: "API Key Name",
      inputPlaceholder: "Type your api key name here...",
      inputAttributes: {
        "aria-label": "API Key Name",
      },
      showCancelButton: true,
      confirmButtonText: "Generate",
      showLoaderOnConfirm: true,
      preConfirm: async (name) => {
        await customAxios.post("api-keys", { name });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          title: "API Key Generated!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        refreshKeys();
      }
    });
  };

  const handleRevokeButton = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to revoke this Key?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, revoke it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await customAxios.delete(`api-keys/${id}`);

        MySwal.fire({
          title: "API Key Revoked!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        refreshKeys();
      }
    });
  };

  return (
    <Stack py={3} spacing={3}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Typography variant="body1">
          Please use the following API Keys to use the APIs.
        </Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          disableElevation
          onClick={handleNewAPIKeyButton}
        >
          New API Key
        </Button>
      </Stack>
      {!isLoading &&
        keys.map((elem) => (
          <Stack key={elem._id} direction="column" spacing={0.5}>
            <Typography variant="body1">{elem.name}</Typography>
            <CopyValue
              value={elem.key}
              revokeFn={() => handleRevokeButton(elem._id)}
            />
          </Stack>
        ))}
    </Stack>
  );
}

export default function ApiIntegration() {
  const [activeTab, setActiveTab] = React.useState("1");

  return (
    <TabContext value={activeTab}>
      <TabList
        onChange={(e, newValue) => setActiveTab(newValue)}
        aria-label="user management tabs"
      >
        <Tab label="oAuth" value="1" />
        <Tab label="API Key" value="2" />
      </TabList>
      <Divider />
      <TabPanel value="1">
        <OAuthCredTab />
      </TabPanel>
      <TabPanel value="2">
        <APIKeyTab />
      </TabPanel>
    </TabContext>
  );
}
